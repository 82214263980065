<template>
  <div class="w-full sm:w-1/2 md:w-1/4 p-4">
    <div
      class="flex flex-col items-center cursor-pointer border border-white container"
      :class="{ hover: hover || !hoverAvalible }"
      @mouseover="hover = true"
      @mouseout="hover = false"
      @click="$emit('click')"
    >
      <div class="hover-only mt-2 text-base absolute z-50">More Info +</div>
      <CoreImage
        class="image-size"
        :image="images[hover && multiImages ? 1 : 0]"
        imgClass="image-size-inner"
      />
      <div
        class="hover-text flex flex-col items-center w-full p-2 border-t"
        :class="bg"
      >
        <h3 class="text-base text-center">{{ title }}</h3>
        <p class="text-sm">{{ pricing }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopThumbnail",

  props: {
    images: {
      type: Array
    },
    title: {
      type: String,
      default: ""
    },
    pricing: {
      type: String,
      default: ""
    },
    type: {
      type: String
    }
  },

  data: () => ({
    hover: false,
    hoverAvalible: false
  }),

  computed: {
    bg() {
      return this.type === "Diamonds" ? "hover-bg-black" : "";
    },
    multiImages() {
      return this.images.length > 1;
    }
  },

  created() {
    this.hoverAvalible = matchMedia("(hover:hover)").matches;
  }
};
</script>

<style scoped>
.hover.container {
  border-color: theme("colors.black");
}

.hover-only {
  opacity: 0;
  pointer-events: none;
  transition-property: opacity;
  transition-duration: var(--transition-short);
  transition-timing-function: var(--transition-ease);
}

.hover .hover-only {
  opacity: 1;
  pointer-events: all;
}

.hover-text {
  border-color: #fff;
}

.hover .hover-text {
  border-color: #000;
}

.hover .hover-bg-black {
  background-color: #000;
  color: #fff;
}

.image-size {
  height: 0;
  padding-bottom: 100%;
  object-fit: contain;
  width: 100%;
  overflow: hidden;
  position: relative;
}

::v-deep .image-size-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>
