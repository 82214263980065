export const isEmail = input => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(input).toLowerCase());
};

export const isPhone = input => {
  // Crude but better than nothing
  const phone = input.replace(/\D+/g, "");
  return phone.length > 6 && phone.length <= 14;
};

export const isNumber = input => Number.isNaN(Number(input));
