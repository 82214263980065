<template>
  <div class="pt-6 pb-2 px-4">
    <span>Filter: </span>
    <span
      v-text="activeFilterUi"
      class="filter-block"
      :class="{ 'opacity-0 pointer-events-none absolute': hover }"
    />
    <span
      class="filter-block"
      :class="{ 'opacity-0 pointer-events-none absolute': !hover }"
    >
      <span
        @click="handleClick('')"
        class="filter-option cursor-pointer hover:text-black"
        :class="[activeFilter === '' ? 'text-black' : 'text-gray']"
      >
        All
      </span>
      <span
        v-for="option in filterOptions"
        :key="option"
        v-text="option"
        @click="handleClick(option)"
        class="filter-option cursor-pointer hover:text-black"
        :class="[activeFilter === option ? 'text-black' : 'text-gray']"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: "ShopFilter",

  props: {
    filterOptions: {
      type: Array
    },
    activeFilter: {
      type: String,
      default: ""
    }
  },

  computed: {
    activeFilterUi() {
      if (this.activeFilter) return this.activeFilter;
      return "All";
    }
  },

  data: () => ({
    hover: true
  }),

  methods: {
    handleClick(option) {
      // this.hover = false;
      this.$emit("update:activeFilter", option);
    }
  }
};
</script>

<style scoped>
.filter-block {
  transition-property: opacity;
  transition-duration: var(--transition-short);
  transition-timing-function: var(--transition-ease);
}

.filter-option:after {
  content: ", ";
}
.filter-option:last-of-type:after {
  content: "";
}
</style>
