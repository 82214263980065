<template>
  <div>
    <p class="text-base">
      {{
        success
          ? "Thank you for your enquiry, we will be in touch shortly."
          : "If you are interested in purchasing this product please get in touch."
      }}
    </p>
    <form
      class="mt-4"
      novalidate="true"
      :class="{ 'opacity-0 pointer-events-none': success }"
    >
      <CoreInput
        label="Full Name"
        :required="true"
        :disabled="processing"
        :value.sync="fullName"
        type="text"
        :error="!!containsError('fullName')"
        :error-message="ERROR_CODES[containsError('fullName')]"
        @blur="$event.interacted && isFullname()"
      />
      <CoreInput
        label="Email or Phone Number"
        :required="true"
        :disabled="processing"
        :value.sync="contact"
        type="text"
        :error="!!containsError('contact')"
        :error-message="ERROR_CODES[containsError('contact')]"
        @blur="$event.interacted && isContact()"
      />
      <CoreInput
        label="Message"
        :disabled="processing"
        :value.sync="message"
        type="text"
        input-type="textarea"
      />
      <button type="button" @click="handleSubmit" class="border p-1 px-2">
        {{ processing ? "Submitting" : "Submit" }}
      </button>
      <p class="text-xs mt-4">
        This site is protected by reCAPTCHA and the Google
        <a class="underline" href="https://policies.google.com/privacy"
          >Privacy Policy</a
        >
        and
        <a class="underline" href="https://policies.google.com/terms"
          >Terms of Service</a
        >
        apply.
      </p>
      <CoreRecapcha ref="recaptcha" class="invisible" />
    </form>
  </div>
</template>

<script>
import { submitApplication } from "@io";
import { isEmail, isPhone } from "@utils/fieldValidation";
import { ERROR_CODES } from "@const";

export default {
  name: "ShopItemContact",

  props: {
    product: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    data: null,
    processing: false,
    fullName: "",
    contact: "",
    message: "",
    errors: new Map([]),
    errorsChangeTracker: 0,
    success: false,
    attempts: 0,
    ERROR_CODES
  }),

  computed: {
    errorsReactive() {
      if (!this.errorsChangeTracker) return [];
      return Array.from(this.errors.entries());
    }
  },

  methods: {
    containsError(fieldName) {
      const error = this.errorsReactive.find(err => err[0] === fieldName);

      if (typeof error === "undefined") return false;

      return error[1];
    },
    validateFields() {
      // Clear prev validationErrors
      this.isFullname();

      this.isContact();

      // Return result
      return this.errors.size === 0;
    },
    isFullname() {
      this.errors.delete("fullName");
      // Check Required fields
      if (this.fullName === "") this.errors.set("fullName", 1);

      this.errorsChangeTracker += 1;
    },
    isContact() {
      this.errors.delete("contact");

      if (this.contact === "") {
        this.errors.set("contact", 1);
        this.errorsChangeTracker += 1;
        return;
      }

      // Validate contact field
      // Email
      const isEmailTrue = isEmail(this.contact);
      const isPhoneTrue = isPhone(this.contact);

      if (!(isEmailTrue || isPhoneTrue)) this.errors.set("contact", 2);

      this.errorsChangeTracker += 1;
    },
    async handleSubmit() {
      // Handle all things validaition
      const valid = this.validateFields();
      if (!valid) return;

      // Lets submit
      this.processing = true;

      // Activate recapture
      const recaptcha = await this.$refs.recaptcha.execute();

      // Need to rename the contct assignemt as already declared
      const { fullName, product, contact, message } = this;

      const result = await submitApplication({
        recaptcha,
        data: {
          fullName,
          product,
          contact,
          message
        }
      });

      this.attempts += 1;

      if (result.data) {
        this.processing = false;
        this.success = true;
      }
    }
  }
};
</script>
