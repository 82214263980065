<template>
  <BlockContainer>
    <div v-if="!!data" class="flex flex-col md:flex-row">
      <!-- LHS -->
      <div class="md:w-1/2 p-5 md:p-20 md:pr-10">
        <ShopItemImages :images="data.images" :video="data.video" />
      </div>
      <!-- RHS -->
      <div class="md:w-1/2 sticky top-0 self-start p-10 pl-5 md:p-20 md:pl-10">
        <h1 class="text-3xl">{{ data.title }}</h1>
        <h2 class="text-xl">{{ data.pricing }}</h2>
        <p class="mt-8 text-base">{{ data.description }}</p>
        <table class="mt-8">
          <tbody>
            <tr v-for="(row, i) in data.dataTable.tableData" :key="i">
              <td
                class="border p-2 px-6 text-base"
                v-for="(cell, j) in row"
                :key="`${i}${j}`"
                v-text="cell"
              />
            </tr>
          </tbody>
        </table>
        <div v-if="!!data.dataSheet && !!data.dataSheet.fields" class="mt-8">
          <a
            :href="data.dataSheet.fields.file.url"
            target="_blank"
            class="text-base"
            >Download the certificate
            <CoreIcon class="h-4 inline ml-2" icon="download"
          /></a>
        </div>
        <ShopItemContact class="mt-8" :product="data.title" />
      </div>
    </div>
  </BlockContainer>
</template>

<script>
import { getProduct } from "@io";
import { BlockContainer } from "@c/Block";
import ShopItemContact from "./ShopItemContact";
import ShopItemImages from "./ShopItemImages";

export default {
  name: "ShopItem",

  components: {
    BlockContainer,
    ShopItemContact,
    ShopItemImages
  },

  data: () => ({
    data: null
  }),

  async created() {
    this.data = await getProduct(this.$route.params.slug);
  }
};
</script>
