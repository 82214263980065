<template>
  <BlockContainer>
    <div class="flex items-center flex-col">
      <div
        class="max-w-screen-xl w-full flex flex-col px-10 md:px-20 pb-10 md:pb-20 pt-5 md:pt-10"
      >
        <ShopFilter
          :filterOptions="filterOptions"
          :activeFilter.sync="activeFilter"
        />
        <div class="flex flex-wrap">
          <ShopThumbnail
            v-for="(item, i) in filteredItems"
            :key="i"
            v-bind="item"
            @click="
              $router.push({ name: 'ShopItem', params: { slug: item.slug } })
            "
          />
        </div>
      </div>
    </div>
  </BlockContainer>
</template>

<script>
import { getProducts } from "@io";
import { BlockContainer } from "@c/Block";
import ShopFilter from "./ShopFilter";
import ShopThumbnail from "./ShopThumbnail";

export default {
  name: "Shop",

  components: {
    BlockContainer,
    ShopFilter,
    ShopThumbnail
  },

  data: () => ({
    data: null,
    activeFilter: ""
  }),

  computed: {
    filterOptions() {
      if (!this.data) return;
      return [...new Set(this.data.flatMap(items => items.type))];
    },
    filteredItems() {
      if (!this.data) return [];
      if (!this.activeFilter) return this.data;
      return this.data.filter(item => item.type === this.activeFilter);
    }
  },

  async created() {
    this.data = await getProducts();
  }
};
</script>

<style scoped>
.w-screen-xl {
  width: 1280px;
}
</style>
