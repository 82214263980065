<template>
  <div>
    <!-- Desktop -->
    <div class="hidden md:block max-w-600">
      <CoreImageZoomer v-for="(image, i) in images" :key="i" :image="image" />
      <CoreVideo
        class="w-full"
        :video="video"
        muted
        autoplay
        playsinline
        loop
      />
    </div>

    <!-- Mobile -->
    <!-- Slider main container -->
    <div ref="swiperContainer" class="swiper-container md:hidden">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <CoreImage
          class="swiper-slide"
          v-for="(image, i) in images"
          :key="i"
          :image="image"
        />
        <CoreVideo
          class="swiper-slide"
          :video="video"
          muted
          autoplay
          playsinline
          loop
        />
      </div>
      <div ref="swiperPagination" class="swiper-pagination" />
    </div>
  </div>
</template>

<script>
import Swiper, { Pagination } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  name: "ShopItemImages",

  props: {
    images: {
      type: Array
    },
    video: {
      type: Object
    }
  },

  mounted() {
    Swiper.use([Pagination]);

    this.swiper = new Swiper(this.$refs.swiperContainer, {
      pagination: {
        el: this.$refs.swiperPagination,
        clickable: true
      },
      loop: true
    });
  }
};
</script>

<style>
@import "swiper/swiper-bundle.css";

.max-w-600 {
  max-width: 600px;
}
</style>
